.App {
  text-align: center;
  /* display: grid;
  grid-template-rows: 1fr 5fr ;
  height:100%;
  width:100%; */
  display: flex;
  height:100%;
  width:100%;
}

body {
  overflow: hidden;
  /* // Prevent scrolling */
}

#root{
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}


 .upload-link{
  display: flex;
  flex-direction: row;
  width: 120px;
  position: relative;
  right: 120px;
 }


.companyLogo{
  margin-left: 17px;
}

.switch{
  width: 50px;
  position: absolute;
  left: 228px;
  z-index: 2;
}


.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 65px;
  display: flex;
  align-items: center;
  background-color: #fff;
  box-shadow: 0 2px 25px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.user-avatar {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.logout-container {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 0 20px;
  width: 130px;
}

.logout{
  font-size: 16px;
  float: right;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 0px;
  width: 40px;
}

.newLogo {
  display: none;
  margin-left: 10px;
  /* 其他样式 */
}

.company-logo {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}




.company-name {
  flex-grow: 1;
  text-align: left;
  margin-left: 10px;
  font-size: 20px;
  font-weight: bold;
  height: 50px;
  line-height: 50px;
}

.UButton {
  background-image: none !important;
  border-radius: 20px  !important;
  background-color: white !important;
  border-color: white !important;
  color: black !important;
  cursor: pointer !important;
 
  letter-spacing: 0.1rem !important;
  padding: 0.9rem 4rem !important;
  text-transform: uppercase !important;
  transition: transform 80ms ease-in !important;
  width: 130px !important;
  height: 40px !important;
  margin: 0px !important;
  text-align: center !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 10px !important;
}

.buttonText{
    width: 80px  !important;
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 500;
    float: right;
    font-family: Arial, Helvetica, sans-serif;
}

/* 当屏幕宽度小于800px时，应用以下样式 */
@media screen and (max-width: 800px) {
  .companyLogo {
    display: none;
  }

  .newLogo {
    display: block;
  }
  .UButton{
    display: none !important;
  }
  .buttonText{
    display: none !important;
  }
}


.content {
  position: relative;
  display: flex;
  flex-direction: row;
  height: calc(100vh - 65px); /* Subtract the height of the top bar */
  overflow: hidden; /* Hide the overflow */
  margin-top: 65px; /* Add this line */
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}
.right-side {
  /* z-index: 2; */
  flex: 4;
  /* overflow: auto; Add a scrollbar if the content overflows */
  padding-top: 0px;
  /* padding: 30px; */
  /* padding-left: 30px;
  padding-right: 30px; */
 
 
  background-color: #fff;
  display: flex;
  /* flex-direction: column; */
  align-items: stretch; /* 使子元素拉伸填满宽度 */
  justify-content: center;
  height: 100%;
  /* width: 100%; */
}

/* 对话消息的基础样式 */
.message {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  margin-top: 30px;
  padding: 8px;
  border-radius: 18px;
  word-wrap: break-word;
  position: relative;
  width: 100% !important;
}

.message span {
  text-align: justify;
  white-space: pre-wrap;
   /* 保留空格和换行，同时允许自动换行 */
}

/* 用户消息 */
.message.user {
  justify-content: flex-start;
  color: black;
}

/* 响应消息 */
.message.response {
  justify-content: flex-start;
  background-color: #f8f5f5;
  color: black;
}

/* 消息文本 */
.message p {
  /* max-width: 70%; */
  margin: 0;
  width:100%;
}

/* 用户头像 */
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-left: 5px;
}

.response-message {
  display: flex;
  align-items:start;
  width:100%;
  flex-direction: row;
}

.spanSum {
    position: inherit;
    flex-shrink: 0;
    width: 95% !important;
    height: 100%;
}

.copy{
  opacity: 0;
  transition: all 0.3s;
  background-color: #fff;
  border-width: 3px;
  position: absolute;
  left: 43px;
  bottom: -13px;
  width: 20px;
  height: 20px;
  /* margin-bottom: 20px; */
  margin-top: 0px;
}

.copy-span{
  text-indent: 2em; 
}

.response-message:hover .copy {
  opacity: 1;
  transition: all 0.3s;
}

.copy:hover{
  transform: scale(1.07);
  background-color: #fff;
}


.copy[title]:after {
  content: "copy";
  color: #fff;
  padding: 4px 8px;
  position: absolute;
  left: 0;
  bottom: -30px;
  z-index: 20;
  white-space: nowrap;
  background-color: black;
  font-size: 10px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 800;
  border-radius: 8px;
  opacity: 0;
  transition-delay: 0.4s;
  transition-property: opacity;
  transition-timing-function: linear;
  transition-duration: 0.8;
}
 
.copy:hover:after {
  opacity: 1;
}

.left-side {
  /* flex: 1; */
  width: 236px;
  /* overflow: auto; */
  padding: 0px;
  padding-top: 0px;
  padding-bottom: 30px;
  box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.1);
  /* background-color: #fff; Add this line */
  background-color: #f9f9f9;
  z-index: 20; 
  display: flex;
  height: 100%;
  position: inherit;
  margin-top: 0;
  /* flex-direction: column;
  justify-content: left;
  align-items: center; */
  transition: opacity 0.3s ease-in-out,width 0.3s ease-in-out;
}

/* 当屏幕宽度小于800px时，应用以下样式 */
@media screen and (max-width: 800px) {
  .left-side {
    display: none;
  }
}

.show {
  display: block;
}

/* .hide {
  display: none;
} */



.left-side-container {
  display: flex;
  flex-direction: column;
  height: 100%; /* Subtract the height of the top bar */
  position: inherit;
  width: 100%;
}

.left-side-container > :first-child {
  flex: 0 0 auto;
}

.left-side-container > :nth-child(2) {
  flex: 1 1 auto;
  overflow-y: auto; /* 如果需要滚动 */
}

.left-side-container > :last-child {
  flex: 0 0 auto;
}



.collapse-button {
  position: absolute;
  left: 245px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 25px; /* 可以根据需要调整高度 */
  display: flex;
  flex-direction: column;
  justify-content: center; /* 调整为center，以便子元素居中对齐 */
  cursor: pointer;
  z-index: 100;
  transition: left 0.3s ease-in-out;
}

/* 当屏幕宽度小于800px时，应用以下样式 */
@media screen and (max-width: 800px) {

  .spanSum {
    position: inherit;
    flex-shrink: 0;
    width: 80% !important;
    height: 100%;
}
  
  .collapse-button {
    display: none;
  }
}


.collapse-button > div {
  width: 0.25rem;
  height: 1rem; /* 增加线条长度 */
  background: #c9c9c9;
  border-radius: 9999px;
  transition: all 0.5s;
  transform-origin: center; /* 设置旋转中心为自身中心 */
}

.collapse-button > div:first-child {
  margin-bottom: -0.25rem; /* 调整这个值直到线条看起来像一条直线 */
}

/* 调整悬停状态下的背景颜色 */
.collapse-button:hover > div {
  background: black;
}

/* 调整第一个和最后一个div的转换效果 */
.collapse-button:hover > div:first-child {
  transform: rotate(15deg);
}

.collapse-button:hover > div:last-child {
  transform: rotate(-15deg);
}

.collapse-button.collapsed {
  left: 10px; /* 移动到最左边 */
}

/* 当collapse-button在collapsed状态时，改变颜色并反转旋转角度 */
.collapse-button.collapsed > div {
  background: black; /* 移动到最左边时的深色 */
}

.collapse-button.collapsed > div:first-child {
  transform: rotate(-15deg);
}

.collapse-button.collapsed > div:last-child {
  transform: rotate(15deg);
}

/* 调整左侧和右侧区域的展开收起效果 */
.left-side.collapsed {
  opacity: 0;
  width: 0;
  overflow: hidden;
}

/* .left-side.collapsed .left-side-container {
  display: none;
} */

.-side.expanded {
  /* flex: 1; */
  width: 400px;
  height: 800px;
}

.button_add {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 90%;
  height: 40px;
  margin: 10px;
  position: relative;
  border-width: 1px;
  letter-spacing: 2px;
  background-color: #f9f9f9;

  overflow: hidden;
  border-radius: 8px;
}


.button_add:hover {
  background-color: rgb(236, 236, 236);
}



.button_clear {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  width: 90%;
  height: 40px;
  margin: 10px;
  position: relative;
  border-width: 1px;
  letter-spacing: 2px;
  background-color: #f9f9f9;
  overflow: hidden;
  border-radius: 8px;
}

.button_clear:hover {
  background-color: rgb(236, 236, 236);
}


.add{
  position: inherit;
  width: inherit;
  flex: 0.5 !important;
}

.clear{
  position: inherit;
  margin: 0px 0px;
  width: inherit;
  flex: 1 !important;
}

span {
  font-weight: 300;
  font-family: "Arial";
  font-size: medium;
  width:100%;
}

.addChatSpan {
  position: relative;
  left: 0;
  text-align: left;
  margin-left: 10px;
  white-space: nowrap;
}

.addChatSpanDec {
  position: relative;
  left: 0;
  text-align: left;
  margin-left: 10px;
  white-space: nowrap;
}


.addChat {
  border-radius: 8px;
  width: 90%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
  cursor: pointer; /* 添加这一行 */
}

.addChat:hover, .addChat:focus {
  background-color: rgb(236, 236, 236);
}

.addChat.selected {
  background-color: rgb(236, 236, 236);
  width: 90%;
}

img {
  width: 20px;
  height: 20px;
}

.dec {
  position: relative;
  left: -40px;
  bottom: 0;
}
.bin {
  width: 18px;
  height: 18px;
  color: rgb(239, 68, 68);
}
.dec_choose {
  position: relative;
  left: -30px;
  bottom: 0;
  color: rgb(62, 142, 252);
}

#AddNewChat {
  /* height: 400px; */
  position: inherit;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  flex:4;
  /* overflow: hidden; */
  /* height: inherit; */
}

/* 自定义滚动条样式 */
#AddNewChat::-webkit-scrollbar {
  width: 8px; /* 滚动条宽度 */
}

#AddNewChat::-webkit-scrollbar-thumb {
  background-color: #c3c0c0; /* 滚动条thumb的颜色 */
  border-radius: 5px; /* 滚动条thumb的圆角 */
}

#AddNewChat::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* 滚动条轨道的颜色 */
}

/* .delete {
  width: 30px;
  height: 30px;
  border-color:red;
  border-radius:5px;
  border-style:solid;
  background-color:transparent;
  display:flex;
  justify-content:center;
  align-items:center;
} */


.delete {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}


.addChatSpan, .addChatSpanDec {
  text-align: left;
  font-size: 14px;
}


.new_chat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  white-space: nowrap;
  
}

.new_chat span {
  font-weight: bold;
  white-space: nowrap;
  text-align: start;
}


.delete_all {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  white-space: nowrap;
}

.delete_all span {
  font-weight: bold;
  text-align: start;
}

.bold-text {
  font-weight: bold;
}
