.customAudio {
    width: 153px; /* 可以根据需求调整宽度 */
    height: 38px; /* 可以根据需求调整高度 */
    border-radius: 18px 31px 31px 0;
    background-color: rgb(194, 229, 245);; /* 背景颜色 */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    left: 6px;
    top:12px;
    position: relative;
}

.ptime{
    position: absolute;
    left: 120px;
    color: #ffffff;
}

.audio-icon{
    position: absolute;
    left: 5px;
}

.dajianshi {

    width: 153px;
    height: 30px;
    display: none;
    position: absolute;
    top:2px;
    left: 60px;    
}

.dajianshi span {
    width: 3px;
    border-radius: 18px;
    margin-right: 5px;
}

.dajianshi span:nth-child(1) {
    animation: bar1 1.5s 0.1.5s infinite linear;
}

.dajianshi span:nth-child(2) {
    animation: bar2 1.5s 0.4s infinite linear;
}

.dajianshi span:nth-child(3) {
    animation: bar3 1.5s 0.6s infinite linear;
}

.dajianshi span:nth-child(4) {
    animation: bar4 1.5s 0.8s infinite linear;
}

.dajianshi span:nth-child(5) {
    animation: bar5 1.5s 1.0s infinite linear;
}

.dajianshi span:nth-child(6) {
    animation: bar6 1.5s 1.2s infinite linear;
}

.dajianshi span:nth-child(7) {
    animation: bar7 1.5s 1.4s infinite linear;
}

.dajianshi span:nth-child(8) {
    animation: bar8 1.5s 1.6s infinite linear;
}

.dajianshi span:nth-child(9) {
    animation: bar9 1.5s 1.8s infinite linear;
}

@keyframes bar1 {
    0% {
        background: white;
        margin-top: 25%;
        height: 10%;
    }

    50% {
        background: #ffffff;
        height: 90%;
        margin-top: 0%;
    }

    100% {
        background: #ffffff;
        height: 10%;
        margin-top: 25%;
    }
}

@keyframes bar2 {
    0% {
        background: #ffffff;
        margin-top: 25%;
        height: 10%;
    }

    50% {
        background: #ffffff;
        height: 90%;
        margin-top: 0%;
    }

    100% {
        background: #ffffff;
        height: 10%;
        margin-top: 25%;
    }
}

@keyframes bar3 {
    0% {
        background: #ffffff;
        margin-top: 25%;
        height: 10%;
    }

    50% {
        background: #ffffff;
        height: 90%;
        margin-top: 0%;
    }

    100% {
        background: #ffffff;
        height: 10%;
        margin-top: 25%;
    }
}

@keyframes bar4 {
    0% {
        background: #ffffff;
        margin-top: 25%;
        height: 10%;
    }

    50% {
        background: #ffffff;
        height: 90%;
        margin-top: 0%;
    }

    100% {
        background: #ffffff;
        height: 10%;
        margin-top: 25%;
    }
}

@keyframes bar5 {
    0% {
        background: #ffffff;
        margin-top: 25%;
        height: 10%;
    }

    50% {
        background: #ffffff;
        height: 90%;
        margin-top: 0%;
    }

    100% {
        background: #ffffff;
        height: 10%;
        margin-top: 25%;
    }
}
 