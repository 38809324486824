.chat-container{
   
    display: grid;
    grid-template-rows: 65% 34%;
    height: inherit;
    /* justify-content: center; */
    align-items: stretch;
    width: 100%;
    justify-items: center;
    background-color: #fff;
  
}

.holder-text{
    display: flex;
    flex-direction: row;
    position: relative;
}

.holder-button{
    top: 16px;
    position: absolute;
    left: 170px;
    width: 27px;
    height: 27px;
}

.top{
    background-color: transparent;
    width: 89%;
    height: 56.33px;
    z-index: 10;
    position: absolute;
    left: 58px;
    border-radius: 25px;

}

.showText-switch{
    position: absolute;
    left: 7px;
    z-index: 10;
    width: 40px;
    height: 40px;
}

.timer-input{
    z-index: 3;
    position: absolute;
    left: 78px;
    width: 10px;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif
}

.interval-input{
    position: absolute;
    left: 58px;
}

.upload-button{
    background-color: transparent;
    height: 24px;
    width: 24px;
    position: absolute;
    z-index: 2;
    padding: 0;
    display: flex;
}

.upload-button:hover{
    background-color: rgba(127, 127, 127, 0.3)  !important;
}

#uploadForm{
    background-color: transparent;
    height: 24px;
    width: 24px;
    left: 67px !important;
    padding: 0;
    position: absolute;
    display: flex;
}


.messages{
    position: relative;
    /* flex: 7; */
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    margin:0px;
}



/* 自定义滚动条样式 */
.messages::-webkit-scrollbar {
    width: 8px; /* 滚动条宽度 */
}

.messages::-webkit-scrollbar-thumb {
    background-color: #c3c0c0; /* 滚动条thumb的颜色 */
    border-radius: 5px; /* 滚动条thumb的圆角 */
}

.messages::-webkit-scrollbar-track {
    background-color: transparent; /* 滚动条轨道的颜色 */
}

.input-container1 {
    /* 新增的样式 */
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    /* 原来样式 */
    display: flex;
    align-items: center;
    position:relative; /* 修改这一行 */
    bottom: 4%;
    width: 70%;
    height:100%;
    max-width: 800px;
    margin-top:5px;
    margin-bottom:5px;
    overflow-y: hidden !important;
    overflow-x: hidden;
   margin-left:10px;
   margin-right:10px;
   padding-left: 52px;
    /* background-color: #fff; */
    /* box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1); */
}
  
  .message-input {
    flex-grow: 1;
    position: relative;
    width: 40%;
    border: 1px solid rgb(191, 191, 191);
    font-size: 15px;
    resize: none;
    overflow-y: auto !important;
    line-height: normal;
    padding: 18px;
    padding-right: 50px; /* 添加这一行 */
    padding-left: 45px;
    border-radius: 25px;
    height: 100% !important;
    flex-shrink:0;
     margin:5px;
  }

.message-input::-webkit-scrollbar {
    opacity:0;
}

.voice-icon{
    right: 44%;
    position: absolute;
    stroke-width: 10px;
}

.message-input:focus {
    outline: none;
}

.send-button {
    text-align: center;
    position: relative; /* 修改这一行 */
    right: 57px; /* 添加这一行 */
    bottom: 16px; /* 添加这一行 */
    width: 24px;
    border-radius: 25px;
    border: none;
    color: gray;
    background-color: #fff;
    cursor: pointer;
    top:1px;
  }

.send-button img {
    width: 24px;
    height: 24px;
    display: block;
}

/* .chat-container {
    display: flex;
    overflow: auto;
    height: 300px;
    width: 1000px;
} */

.TitleName{
    margin-top: 10px;
    padding-top: 100px;
    /* margin-left: 100px; */
}
.contentSpan{
    position: relative;
    left: 0;
    bottom: -4px;
    /* display: flex; */
    margin-top:5px;
}
.contentSpan ol li>p, .contentSpan ul li>p {
  display: inline;
    margin: 0;
}

.contentSum{
    display: flex;
    flex-direction: column;
    height: inherit;
}

.contentSpanUser{
    position: relative;
    left: 0;
    bottom: -2px;
    display: flex;
    margin-top:5px;
}


@media screen and (max-width: 800px) {

    .voice-icon{
        right: 38%;
        position: absolute;
        stroke-width: 10px;
    }
    .message-input{
        flex-grow: 1;
        position: relative;
        width: 72%;
        border: 1px solid rgb(191, 191, 191);
        font-size: 15px;
        resize: none;
        overflow-y: auto !important;
        line-height: normal;
        padding: 18px;
        padding-right: 18px;
        padding-left: 45px;
        border-radius: 25px;
        height: 100% !important;
        flex-shrink: 0;
        margin: 5px;
        margin-right: 1px;
        height: 57.33px !important;
    }
    .send-button img{
        width: 24px;
        height: 24px;
        display: block;
        right: -22px;
        bottom: -11px;
        position: absolute;
    }
  }