.login-container {
    max-width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
    position: inherit;
    height: 250px;
    display: flex;
    flex-direction: column;
  }
  
  .input-container {
    margin-bottom: 10px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input[type="email"],
  input[type="password"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 3px;
    box-sizing: border-box;
  }
  
  button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  .chat-data {
    max-width: 100%;
    word-break: break-word;
    white-space: normal;
  }


  .loginButton{
    margin-top: 30px;;
    background-color: rgb(40,167,69);
  }
  

  :root {
    /* COLORS */
    --white: #e9e9e9;
    --gray: #333;
    --blue: #0367a6;
    --lightblue: #008997;

    /* RADII */
    --button-radius: 0.7rem;

    /* SIZES */
    --max-width: 758px;
    --max-height: 420px;

    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  .body1 {
    align-items: center;
    background-color: var(--white);
    background: url("../img/blue1.png");
    /* 决定背景图像的位置是在视口内固定，或者随着包含它的区块滚动。 */
    /* https://developer.mozilla.org/zh-CN/docs/Web/CSS/background-attachment */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    height: 100vh;
    place-items: center;
    width: 100vw;
  }

  .form__title {
    font-weight: 300;
    margin: 0;
    margin-bottom: 1.25rem;
      width:350px;
  }

  .link {
    color: var(--gray);
    font-size: 0.9rem;
    margin: 1.5rem 0;
    text-decoration: none;
  }

  .container {
    background-color: var(--white);
    border-radius: var(--button-radius);
    box-shadow: 0 0.9rem 1.7rem rgba(0, 0, 0, 0.25),
      0 0.7rem 0.7rem rgba(0, 0, 0, 0.22);
    height: var(--max-height);
    max-width: var(--max-width);
    overflow: hidden;
    position: relative;
    width: 100%;
  }

  .container__form {
    height: 100%;
    position: absolute;
    top: 0;
    transition: all 0.6s ease-in-out;
  }

  .container--signin {
    left: 0;
    width: 50%;
    z-index: 2;
  }

  .container.right-panel-active .container--signin {
    transform: translateX(100%);
  }

  .container--signup {
    left: 0;
    opacity: 0;
    width: 50%;
    z-index: 1;
  }

  .container.right-panel-active .container--signup {
    animation: show 0.6s;
    opacity: 1;
    transform: translateX(100%);
    z-index: 5;
  }

  .container__overlay {
    height: 100%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 0;
    transition: transform 0.6s ease-in-out;
    width: 50%;
    z-index: 100;
  }

  .container.right-panel-active .container__overlay {
    transform: translateX(-100%);
  }

  .overlay {
    background-color: var(--lightblue);
    background: url("../img/blue.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    left: -100%;
    position: relative;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
    width: 200%;
  }

  .container.right-panel-active .overlay {
    transform: translateX(50%);
  }

  .overlay__panel {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: absolute;
    text-align: center;
    top: 0;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
    width: 50%;
  }

  .overlay--left {
    transform: translateX(-20%);
  }

  .container.right-panel-active .overlay--left {
    transform: translateX(0);
  }

  .overlay--right {
    right: 0;
    transform: translateX(0);
  }

  .container.right-panel-active .overlay--right {
    transform: translateX(20%);
  }

  .btn {
    background-color: var(--blue);
    background-image: linear-gradient(90deg, var(--blue) 0%, var(--lightblue) 74%);
    border-radius: 20px;
    border: 1px solid var(--blue);
    color: var(--white);
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: bold;
    letter-spacing: 0.1rem;
    padding: 0.9rem 4rem;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
    width: auto;
  }
 

  .form>.btn {
    margin-top: 1.5rem;
  }

  .btn:active {
    transform: scale(0.95);
  }

  .btn:focus {
    outline: none;
  }

  .form {
    background-color: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 3rem;
    height: 100%;
    text-align: center;
  }

  .input {
    background-color: #fff;
    border: none;
    padding: 0.9rem 0.9rem;
    margin: 0.5rem 0;
    width: 100%;
  }

  .contentSpan{
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      width:100%;
  }

  .buttonContent{
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-weight: 200;
      font-size: medium;
  }

  @keyframes show {

    0%,
    49.99% {
      opacity: 0;
      z-index: 1;
    }

    50%,
    100% {
      opacity: 1;
      z-index: 5;
    }
  }

  .foot{
      position: relative;
      bottom: -30px;
      font-size: smaller;
  }


  @media screen and (max-width: 800px) {
    .container--signup {
      left: 0;
      opacity: 0;
      width: 50%;
      z-index: 1;
      display: none;
    }
  
    .container.right-panel-active .container--signup {
      animation: show 0.6s;
      opacity: 1;
      transform: translateX(100%);
      z-index: 5;
      display: none;
    }

    .container.right-panel-active .overlay--left {
      transform: translateX(0);
      display: none;
    }

    .container.right-panel-active .overlay {
      transform: translateX(50%);
      display: none;
    }

    .container.right-panel-active .container--signin {
      transform: translateX(0%);
      width: 100%;
      background:url("../img/blue.jpg");
    }
  
    .container {
      background-color: var(--white);
      border-radius: var(--button-radius);

      box-shadow: 0 0.9rem 1.7rem rgba(0, 0, 0, 0.25),
        0 0.7rem 0.7rem rgba(0, 0, 0, 0.22);
      height: var(--max-height);
      max-width: var(--max-width);
      overflow: hidden;
      position: relative;
      width: 80%;
      background:url("../img/blue.jpg");
    }
    .body1{
      background:url("../img/blue1.png");
    }
    .container.right-panel-active{
      background:url("../img/blue.jpg");
    }

    .container.right-panel-active .overlay--left {
      transform: translateX(0);
      display: none;
    }
  
    .overlay--right {
      right: 0;
      transform: translateX(0);
      display: none;
    }

    .container--signin {
      left: 0;
      width: 100%;
      z-index: 2;
      background:url("../img/blue.jpg");
    }
    .container__overlay {
      height: 100%;
      left: 50%;
      overflow: hidden;
      position: absolute;
      top: 0;
      transition: transform 0.6s ease-in-out;
      width: 50%;
      z-index: 100;
      display: none;
    }

    .form {
      background-color: var(--white);
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 3rem;
      height: 100%;
      text-align: center;
      background:url("../img/kkkk.jpg");
    }

    .btn {
      background-color: lightblue;
      /* background-image: linear-gradient(90deg, var(--blue) 0%, var(--lightblue) 74%); */
      border-radius: 20px;
      border: 1px solid var(--blue);
     
      cursor: pointer;
      font-size: 0.8rem;
      font-weight: bold;
      letter-spacing: 0.1rem;
      padding: 0.9rem 4rem;
      text-transform: uppercase;
      transition: transform 80ms ease-in;
      width: 50px;
      height: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .buttonContent{
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-weight: 200;
      font-size: small;
      width: 50px;
  }

  .form__title {
    font-weight: 300;
    margin: 0;
    margin-bottom: 1.25rem;
    font-size: 18px;
    /* display: none; */
  }
     
  }
